@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

html,
body {
  height: 100%;
  width: 100%;
  background-color: white;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.definedWidth {
  width: 95%;
}

.red {
  color: red;
}
